<template>
  <div
    class="program border rounded p-3"
    :class="{'program_hidden': programInfo.isHidden}"
  >
    <div class="program-detail d-flex justify-content-between mb-3">
      <div :class="['program-name', { 'program-name_deactivated' : !programInfo.isActive }]">
        {{ programInfo.name }}
      </div>

      <div class="deactivation-section d-flex">
        <div class="deactivation-button ml-3">
          <b-button
            v-if="programInfo.isActive && checkFeatureAccess({
              name: 'Деактивация программы',
              url: '/insurance-companies/:company/programs',
            })"
            variant="outline-danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            :disabled="loading"
            @click="deactivationHandler"
          >
            Деактивировать
          </b-button>

          <div v-else>
            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              disabled
            >
              Закрыто
            </b-button>
            <div class="closed-description">
              {{ deactivationReason }}
            </div>
          </div>
        </div>

        <div
          v-if="checkFeatureAccess({ name: 'Кнопка скрытия программы', url: '/insurance-companies' })"
        >
          <b-button
            variant="outline-secondary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-3"
            size="sm"
            :disabled="loading"
            @click="openAgreeModal"
          >
            {{ programInfo.isHidden ? 'Убрать из скрытых' : 'Скрыть' }}
          </b-button>
        </div>
      </div>
      <div
        v-if="false"
        class="program-controls"
      >
        <router-link
          class="program-control"
          :to="`/insurance-companies/${programInfo.companyId}/programs/${programInfo.id}/subprograms/:subprogram`"
        >
          <icon-info class="crm-icon" />
        </router-link>
        <icon-letter class="program-control crm-icon" />
      </div>
    </div>

    <div class="program-subprograms mx-2 pt-2 px-2">
      <div class="program-subprogram-header d-flex justify-content-between align-items-center">
        <div class="text-muted">
          Подпрограммы
        </div>
        <div>
          <b-button
            v-if="checkFeatureAccess({ name: 'Возможность видеть скрытые подпрограммы', url: '/insurance-companies' })"
            v-b-tooltip.hover
            size="md"
            variant="link"
            :type="$const.PRIMARY_BUTTON"
            class=""
            :title="showHiddens ? 'Показать скрытые' : 'Показать не скрытые'"
            :disabled="searchHiddens"
            @click="getSubprograms"
          >
            <b-icon
              v-if="showHiddens"
              icon="eye"
            />

            <b-icon
              v-else
              icon="eye-slash"
            />
          </b-button>

          <b-button
            v-if="programInfo.isActive
              && checkFeatureAccess({ name: 'Создание подпрограммы', url: '/insurance-companies' })"
            v-b-tooltip.hover
            size=""
            variant="link"
            :type="$const.PRIMARY_BUTTON"
            class=""
            title="Добавить подпрограмму"
            @click="$router.push(`/insurance-companies/${programInfo.companyId}/subprograms/new`)"
          >
            <b-icon icon="plus-lg" />
          </b-button>
        </div>
      </div>
      <div class="program-subprogram-list d-flex flex-wrap justify-content-between ">
        <template v-if="sortedSubprogram.length">
          <router-link
            v-for="subprogram in isHideSubprograms ? sortedSubprogram.slice(0, 3): sortedSubprogram"
            :key="subprogram.id"
            :class="['program-subprogram text-primary', { 'program-subprogram_deactivated' : !subprogram.isActive }]"
            :to="`/insurance-companies/${programInfo.companyId}/programs/${programInfo.id}/subprograms/${subprogram.id}`"
          >
            {{ subprogram.name }}
          </router-link>
        </template>

        <p
          v-else
          class="font-weight-bold"
        >
          Подпрограмм нет
        </p>

        <!-- v-if="!checkRoleAccess_(['Operator'])" -->
      </div>
      <div
        v-if="program.subPrograms.length > 3"
        class="subprogram-button text-primary ml-auto mt-0"
        @click="() => isHideSubprograms = !isHideSubprograms"
      >
        <template v-if="isHideSubprograms">
          Показать все
        </template>
        <template v-else>
          Скрыть
        </template>
        <b-icon
          icon="chevron-down"
          class="ml-2"
          :class="{ reverse: !isHideSubprograms }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { sortBy, partition, concat } from 'lodash';
import IconLetter from 'assets/images/letter_icon.svg';
import IconInfo from 'assets/images/info_icon.svg';
import { api } from '@/helpers/api';

export default {
  name: 'InsuranceProgram',
  components: {
    IconLetter,
    IconInfo,
  },
  mixins: [mixinRoles],
  props: {
    program: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isHideSubprograms: true,
      deactivationReasons: [
        'Программа не продается (обследование продолжается)',
        'Программа не активна (все полисы деактивированы)',
      ],
      loading: false,
      showHiddens: false,
      searchHiddens: false,
      searchedSubprograms: null,
    };
  },
  computed: {
    subprograms() {
      if (this.searchedSubprograms) return this.searchedSubprograms;

      return this.program.subPrograms.map((item) => ({ ...item, id: item.subprogramId }));
    },
    sortedSubprogram() {
      const groupedArrays = partition(this.subprograms, 'isActive');
      const sortedActive = sortBy(groupedArrays[0], 'name');
      const sortedNotActive = sortBy(groupedArrays[1], 'name');
      return concat(sortedActive, sortedNotActive);
    },
    programInfo() {
      return this.program.program;
    },
    deactivationReason() {
      return this.deactivationReasons[this.programInfo.reasonDeactivation];
    },
  },
  created() {
    if (this.program.subPrograms.length <= 3) this.isHideSubprograms = false;
    if (this.program && this.program.program) this.showHiddens = this.program.program.isHidden;
  },
  methods: {
    deactivationHandler() {
      this.$emit('deactivationHandler', this.programInfo);
    },
    async getSubprograms() {
      this.searchHiddens = true;

      try {
        const { data: subprograms } = await api.get(`v2/insuranceCompanies/programs/${this.program.program.id}/subprograms`, {
          params: {
            getHidden: !this.showHiddens,
          },
        });
        this.searchedSubprograms = subprograms;

        this.showHiddens = !this.showHiddens;
      } catch (e) {
        console.error(e);
      } finally {
        this.searchHiddens = false;
      }
    },
    async hideOrShowProgram() {
      await this.$store.dispatch(this.$types.PROGRAM_HIDE_OR_SHOW, {
        params: { programId: this.program.program.id },
      });

      this.$emit('reload');
    },
    openAgreeModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.hideOrShowProgram },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.program {
  background: #fff;
  // box-shadow: 0px 1px 2px #C4C4C4;
  // border-radius: 10px;
  // padding: 20px;
  // display: flex;
  box-sizing: border-box;
  // flex-wrap: wrap;

  // @media screen and (max-width: 991px) {
  //   flex-direction: column;
  // }

  &_hidden {
    opacity: 0.5;
  }
}

.program-detail {
  box-sizing: border-box;

}

.program-name {
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  &_deactivated {
    color: $gray-dark;
  }
}

.program-controls {
  margin-top: 15px;
}

.program-control {
  cursor: pointer;

  & + & {
    margin-left: 15px;
  }
}
.deactivation-section {
}
.closed-label {
  color: #EB5757;
  background-color: #FFDCDC;
  border-radius: 20px;
  width: 100px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.closed-description {
  margin-top: 15px;
  color: $black-light;
  font-size: 12px;
  line-height: 16px;
}
.program-subprograms {
  box-sizing: border-box;
  // flex: 1;
  // flex-shrink: 2;
  border-top: 1px solid #E0E0E0;
  // padding-left: 10px;
  // margin-left: 10px;

  @media screen and (max-width: 991px) {
    border-left: unset;
    padding-left: 0;
    margin-left: 0;
    border-top: 1px solid #E0E0E0;
    padding-top: 10px;
    margin-top: 10px;
  }
}

// .program-subprograms-title {
//   font-size: 14px;
//   line-height: 18px;
//   color: #707070;
//   margin-bottom: 5px;
// }

::v-deep.program-subprogram {
  cursor: pointer;
  font-weight: 500;
  // font-size: 16px;
  // line-height: 20px;
  // color: #6E88F3;
  text-decoration: none;
  // display: block;
  width: calc(50% - 20px);
  margin-top: 10px;

  &_deactivated {
    color: #C7C7C7;
  }
}

// ::v-deep.program-subprogram + .program-subprogram {
//   margin-top: 10px;
// }

.subprogram-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  .crm-icon-cross {
    flex-shrink: 0;
    margin-right: 10px;
    path {
      fill: #4B62C1;
    }
  }

  .reverse{
      transform: rotate(180deg);
    }
  }

</style>
